import React from "react"
import { graphql } from "gatsby"
import { Container } from "../components/Container"
import GatsbyImage from "gatsby-image"
import { Layout } from "../components/Layout"

export default ({ data }) => (
  <Layout
    hero
    seo={{
      title: "Energieconsultancy voor de toekomst",
      path: "/",
      description:
        "Samenwerken naar meer duurzame energie voor een groen Nederland. Wij helpen met het ontwikkelen van energieprojecten in Nederland.",
    }}
  >
    <section id="voordelen">
      <Container>
        <div className="description">
          <h2>Hoe bereiken we samen de klimaatdoelen binnen Nederland?</h2>
          <p>
            Enervar begeleidt en helpt u bij het proces van verduurzaming. Voor
            het aanleggen van duurzame projecten komen er veel verschillende
            partijen en factoren bij kijken. Enervar zorgt ervoor dat alle
            betrokken partijen aan tafel zullen worden gebracht zodat zowel de
            opstart en aanleg als de nazorg van een project naar behoren
            verloopt.
          </p>
        </div>
        <div className="green box">
          <h2>Voordelen</h2>
          <ul>
            <li>Netwerk van professionals</li>
            <li>Volledige ontzorging</li>
            <li>Duurzame onderneming</li>
          </ul>
        </div>
      </Container>
    </section>
    <section id="highlight">
      <Container>
        <GatsbyImage {...data.solarHome.childImageSharp} />
        <div>
          <h2>Bespaar 800 kg CO2 voor maar €120</h2>
          <p>
            Momenteel is enervar betrokken met <strong>ValleiEnergie</strong>{" "}
            bij de ontwikkeling van projecten zonne-energie en windenergie. Het
            grootste project momenteel is zonneproject Meikade tussen Ede en
            Lunteren in waar ruim 5.000 panelen worden gelegd op zowel dak als
            veld.
          </p>
          <p>
            Verder zijn er een aantal projecten nog in de voorbereidende fase
            bij ValleiEnergie.
          </p>
          <p style={{ textAlign: "right", marginTop: 12 }}>
            <a
              className="green button"
              href="https://valleienergie.nl/zonnecentrale-meikade/"
              target="_blank"
            >
              Lees Meer
            </a>
          </p>
        </div>
      </Container>
    </section>
  </Layout>
)

export const query = graphql`
  {
    solarHome: file(relativePath: { eq: "project-highlight.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
